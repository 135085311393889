import React from 'react'
import { MainRoutes } from '../MainRoutes.tsx'

interface mainParams {
  MOBILE:boolean,
  USER_NAME:string
}

export function MainCTX({MOBILE,USER_NAME}:mainParams): JSX.Element {
    return (
        <MainRoutes MOBILE={MOBILE} USER_NAME={USER_NAME} />
    );
}

export default MainCTX;