import React from "react";
import { Route, Routes } from "react-router-dom";
// import { MainLayout } from "./Layouts/MainLayout.tsx";
// import { Search } from "./Pages/Search.tsx";
// import { RMA } from "./Pages/RMA.tsx";
import { ReturnAsset } from "./Pages/ReturnAsset.tsx";
// import { FinalQA } from "./Pages/FinalQA.tsx";
import { UPSLayout } from "./Layouts/UPSLayout.tsx";
// import { Admin } from "./Pages/Admin.tsx";

interface routesParams {
    MOBILE:boolean,
    USER_NAME:string,
}

export function MainRoutes({MOBILE, USER_NAME}:routesParams): JSX.Element{
    return (
        <Routes>
            {/* <Route path="/" element={<MainLayout MOBILE={MOBILE} USER_NAME={USER_NAME} />}>
                <Route path='Search' element={<Search USER_NAME={USER_NAME} />} />
                <Route path="RMA" element={<RMA USER_NAME={USER_NAME}/>} />
                <Route path="FinalQA" element={<FinalQA USER_NAME={USER_NAME}/>} />
                <Route path="Admin" element={<Admin USER_NAME={USER_NAME}/>} />
            </Route> */}
            <Route path="/ups" element={<UPSLayout MOBILE={MOBILE} USER_NAME={USER_NAME} />}>
                <Route index element={<ReturnAsset USER_NAME={USER_NAME}/>} />
            </Route>
        </Routes>
    )
}
