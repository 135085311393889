import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MainCTX from './ContextHandlers/MainCTX.tsx';
import { BrowserRouter } from "react-router-dom";


let MOBILE = false
if (navigator.userAgent.includes('Mobile')){
  MOBILE = true
}
// I WILL BE PUTTING AZURE AUTHENTICATION HERE AT THE TOP LEVEL AT SOME POINT SOON
// BUT FOR NOW LET'S HARD CODE A USER NAME
const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <BrowserRouter>
      <MainCTX MOBILE={MOBILE} USER_NAME={'Philip Dailey'}/>
    </BrowserRouter>
  );
} else {
  console.error('Failed to find the root element');
}

