
// const standardSet = (input_str:string):{} => {
//     if (input_str != '[ENTER]'){
//         return {'instruction':'set','value':input_str}
//     }else{
//         return {'instruction':'enter'}
//     }
// }
const standardSet = (input_params:{}):{} => {
    // if (input_params['string'] != '[ENTER]'){
    if (input_params['string_0'] != '[ENTER]'){
        return {'instruction':'set','value':input_params['string_0']}
    }else{
        return {'instruction':'enter'}
    }
}

// const standardDelete = (input_str:string):{} => {
//     return {'instruction':'delete','value':input_str}
// }
const standardDelete = (input_params:{}):{} => {
    return {'instruction':'delete','value':input_params['string_0']}
}

// const standardModal = (modal_title, modal_description) => {
//     return {'instruction':'activate_modal','modal_title':modal_title, 'modal_description':modal_description}
// }
const standardModal = (input_params:{}) => {
    return {'instruction':'activate_modal','modal_title':input_params['modal_title'], 'modal_description':input_params['modal_description'], 'qrcode':input_params['qrcode']}
}

const CONTEXT_DICT = {
    'Search':{'qr-mac-input':standardSet},
    'ReturnAsset':{'qr-input':standardSet,'login-input':standardSet,'qrcode':standardSet,'modelNumber':standardSet,'customerSite':standardSet,'serviceOrder':standardSet,'customerTrackingNumber':standardSet,'fieldComment':standardSet,'socsNumber':standardSet,'upsAutoKYNumber':standardSet},
    'RMA':{'qr-input':standardSet,'repairCode':standardSet,'revisionCode':standardSet,'partNumber':standardSet,'partDescription':standardSet,'technicianID':standardSet,'internalTrackingNumber':standardSet, 'receive-rma':standardModal, 'confirm-details':standardModal},
    'FinalQA':{'qr-input':standardSet,'product-dropdown':standardSet, 'delete-qr':standardDelete}
}

// export function inputCTXRouter(input_str:string, ID:string, page:string):{}{
export function inputCTXRouter(input_params:{}, ID:string, page:string):{}{
    // console.log('SANITY CHECK IN CONTEXT')
    // // console.log(input_str)
    // console.log(input_params)
    // console.log(ID)
    // console.log(page)
    // console.log('SANITY CHECK IN CONTEXT')
    // return CONTEXT_DICT[page][ID](input_str)
    return CONTEXT_DICT[page][ID](input_params)
}

