import React from 'react';
import { Fragment, Suspense } from "react";
import { useOutlet } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import NavBar from '../Components/NavBar.tsx';
import { UPSLogo } from '../Components/UPSLogo.tsx';


interface layoutParams {
    MOBILE:boolean,
    USER_NAME:string,
}

export function UPSLayout({MOBILE, USER_NAME}:layoutParams): JSX.Element {
  const outlet = useOutlet();

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
    typography:{
      fontFamily:[
        'Arial',
      ].join(',')
    }
  });

  return (
    <Fragment>
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Suspense children={outlet} />
        </ThemeProvider>
    </Fragment>
  );
}