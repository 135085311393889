export function SendMessage(route: string, message: {}): Promise<string> {
    return new Promise((resolve, reject) => {
        const ws = new WebSocket(`${route}`);

        // console.log(`Sending To ${route}:\n${JSON.stringify(message)}`);
        ws.onopen = () => {
            ws.send(JSON.stringify(message));
        };

        ws.onmessage = (event) => {
            ws.close();
            // console.log(`Received From WS:\n${event.data}`);
            // resolve(event.data.toString());
            resolve(event.data);
        };

        ws.onerror = (error) => {
            ws.close();
            // console.error(`WebSocket error:\n${error}`);
            reject(error.toString()); 
        };
    });
};