import React, { useState }  from "react";
import { Alert, Box, Button, Card, CardContent, Container, Modal, Paper, Snackbar, Stack, TextField, Typography, Input} from '@mui/material';
import { inputCTXRouter } from "../ContextHandlers/InputCTX.tsx";
import { SendMessage } from "../DataHandlers/WebsocketInstance.tsx";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { green, red } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';



export function ReturnAsset({USER_NAME}:{USER_NAME:string}):JSX.Element {
    const [message, set_message] = useState({});
    const [login, set_login] = useState(false)
    const [login_failed, set_login_failed] = useState(false)
    const [login_success_alert, set_login_success_alert] = useState(false)
    const [recieved_message, set_recieved_message] = useState({});
    const [no_data, set_no_data] = useState(false);
    const [temp_scanned_value, set_temp_scanned_value] = useState('');
    const [scanned_value, set_scanned_value] = useState('');
    const [product, set_product] = useState('');
    const [modal_alert_open, set_modal_alert_open] = useState(false);
    const [show_alert, set_show_alert] = useState(false);
    const [return_successful, set_return_successful] = useState(false)
    const [return_unsuccessful, set_return_unsuccessful] = useState(false)
    const [pdfFile, setPdfFile] = useState<File | null>(null);
    const [pdf_file_data, set_pdf_file_data] = useState<ArrayBuffer | null>(null);


    // THIS NEEDS TO BE REFACTORED TO POPULATE FROM BACKEND
    const [ui_dict,set_ui_dict] = useState({
        RETURN_FORM:{
            qrcode:{value:'',label:'Product Serial'},
            modelNumber:{value:'',label:'Part Number'},
            socsNumber:{value:'',label:'SOCS Number'},
            upsAutoKYNumber:{value:'',label:'Auto KY Number'},
            customerSite:{value:'',label:'Installation Facility'},
            serviceOrder:{value:'',label:'PO Number'},
            customerTrackingNumber:{value:'',label:'Return Tracking Number'},
            fieldComment:{value:'',label:'Problem Description'} 
        }
    });
    // THIS NEEDS TO BE REFACTORED TO POPULATE FROM BACKEND

    function arrayBufferToBase64(buffer) {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result) {
                setPdfFile(file);
                set_pdf_file_data(reader.result)     
            }else{
                setPdfFile(null);
                set_pdf_file_data(null);
            }
        };
        reader.readAsArrayBuffer(file);
    };
    
    // const handleInput = (input_str:string, ID:string, page:string, UI_GROUP:string) => {
    const handleInput = (input_params:{}, ID:string, page:string, UI_GROUP:string) => {
        // const input_dict = inputCTXRouter(input_str,ID,page)
        const input_dict = inputCTXRouter(input_params,ID,page)
        if (input_dict['instruction'] === 'set' && UI_GROUP === 'PRODUCT_ENTRY'){
            // set_temp_scanned_value(input_str)
            set_temp_scanned_value(input_dict['value'].trim())
            set_message({...{'params':{'data':input_dict['value'],'context_id':1},'metadata':{'Warp':'Drive'}}})
        }else if (input_dict['instruction'] === 'set' && UI_GROUP === 'RETURN_FORM'){
            ui_dict['RETURN_FORM'][ID]['value'] = input_dict['value']
            set_ui_dict({...ui_dict})
            set_message({...{'params':{'data':ui_dict['RETURN_FORM'],'context_id':2},'metadata':{'Warp':'Drive'}}})
        }else if (input_dict['instruction'] === 'set' && UI_GROUP === 'LOGIN_ENTRY'){
            // set_temp_scanned_value(input_str)
            set_temp_scanned_value(input_dict['value'])
            set_message({...{'params':{'data':input_dict['value'],'context_id':7},'metadata':{'Warp':'Drive'}}})
        }
        else{
            handleSendMessage()
        }
    }

    const handleSendMessage = async () => {
        try {
            let response:string
            if (pdfFile != null){
                let temp_data = JSON.parse(JSON.stringify(ui_dict['RETURN_FORM']));
                temp_data['PDF_DATA'] = arrayBufferToBase64(pdf_file_data);
                response = await SendMessage(process.env.REACT_APP_MANUFACTURING_ZOOM_ZOOM_WS as string, {'params':{'data':temp_data,'context_id':2},'metadata':{'Wrap':'Drive'}});
            }else{
                response = await SendMessage(process.env.REACT_APP_MANUFACTURING_ZOOM_ZOOM_WS as string, message);
            }

            // const response = await SendMessage(process.env.REACT_APP_MANUFACTURING_ZOOM_ZOOM_WS as string, message);
            
            let temp_recieved_message = JSON.parse(response)['data'][0]

            // let temp_recieved_message_complete = JSON.parse(response)['data']
            if (message['params']['context_id'] === 1 && (temp_recieved_message === 'Product Not Defined' || temp_recieved_message === 'NO DATA')){
                set_scanned_value(message['params']['data'])
                set_temp_scanned_value('')
                set_no_data(true)
            }else if (message['params']['context_id'] === 1){
                set_product(temp_recieved_message)
                ui_dict['RETURN_FORM']['qrcode']['value'] = message['params']['data']
                set_ui_dict({...ui_dict})
                set_scanned_value(message['params']['data'])
                set_temp_scanned_value('')
                set_no_data(false)
            }else if (message['params']['context_id'] === 2 && temp_recieved_message === true && JSON.parse(response)['data'][1] === true){
                set_modal_alert_open(false)
                set_scanned_value('')
                set_temp_scanned_value('')
                set_message({})
                set_return_successful(true)
                set_ui_dict({...{RETURN_FORM:{
                    qrcode:{value:'',label:'Product Serial'},
                    modelNumber:{value:'',label:'Model Number'},
                    socsNumber:{value:'',label:'SOCS Number'},
                    upsAutoKYNumber:{value:'',label:'Auto KY Number'}, 
                    customerSite:{value:'',label:'Installation Site'},
                    serviceOrder:{value:'',label:'Service Order'},
                    customerTrackingNumber:{value:'',label:'Tracking Number'},
                    fieldComment:{value:'',label:'Field Comment'}, 
                }}})
                setPdfFile(null)
                set_pdf_file_data(null)
            }else if (message['params']['context_id'] === 2 && temp_recieved_message === true && JSON.parse(response)['data'][1] === false){
                set_modal_alert_open(false)
                set_scanned_value('')
                set_temp_scanned_value('')
                set_message({})
                set_return_unsuccessful(true)
                set_ui_dict({...{RETURN_FORM:{
                    qrcode:{value:'',label:'Product Serial'},
                    modelNumber:{value:'',label:'Model Number'},
                    socsNumber:{value:'',label:'SOCS Number'},
                    upsAutoKYNumber:{value:'',label:'Auto KY Number'}, 
                    customerSite:{value:'',label:'Installation Site'},
                    serviceOrder:{value:'',label:'Service Order'},
                    customerTrackingNumber:{value:'',label:'Tracking Number'},
                    fieldComment:{value:'',label:'Field Comment'}, 
                }}})
                setPdfFile(null)
                set_pdf_file_data(null)
            }else if (message['params']['context_id'] === 7){
                if (temp_recieved_message === true){
                    set_login(true)
                    set_login_success_alert(true)
                    set_temp_scanned_value('')
                    set_scanned_value('')
                }else{
                    set_temp_scanned_value('')
                    set_scanned_value('')
                    set_login(false)
                    set_login_failed(true)
                }
            }
        } catch (err) {
            // console.log(err)
            set_recieved_message(err)
        }
    };

    return (
        <Container sx={{display:'flex', justifyContent:'center', paddingTop:'60px', paddingBottom:'60px'}}>
            {/* POOR MANS AUTHENTICATION FOR THE CUSTOMER UNTIL I HAVE RECEIVED FURTHER DIRECTION ON WHAT KIND OF AUTHENTICATION THEY WOULD LIKE TO USE */}
            {login === false &&
                <Stack direction='column'>
                    <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <Paper elevation={8} sx={{width:'450px',paddingLeft:'25px', paddingRight:'25px', paddingBottom:'30px', paddingTop:'0px', textAlign:'center'}}>
                            <img style={{display:'absolute', marginTop:'5px', marginBottom:'-50px'}}  src={require('../Components/UPS_Logo_Alpha.png')} width={"50%"} height={"50%"}></img>
                            <Stack direction='column' sx={{alignItems:'center'}}>
                                <Stack direction='row' sx={{marginLeft:'53px'}}>
                                    <p style={{fontSize:'30px'}}>Return My Asset</p>
                                    <img style={{display:'absolute', marginTop:'38px', marginLeft:'9px'}} src={require('../Components/TrackonomyBeaconLogo.png')} width={"9%"} height={"9%"}></img>
                                </Stack>
                                <TextField
                                    type="password" 
                                    onClick={e => set_message({...{'params':{'data':temp_scanned_value,'context_id':7},'metadata':{'Warp':'Drive'}}})} 
                                    id="login-input"
                                    label="UPS Login" 
                                    variant="outlined" 
                                    sx={{width:'300px'}}
                                    // onChange={(e) => handleInput(e.target.value,'login-input','ReturnAsset','LOGIN_ENTRY')}
                                    onChange={(e) => handleInput({'string_0':e.target.value},'login-input','ReturnAsset','LOGIN_ENTRY')}
                                    value={temp_scanned_value}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter'){
                                            // handleInput('[ENTER]','login-input','ReturnAsset','LOGIN_ENTRY')
                                            handleInput({'string_0':'[ENTER]'},'login-input','ReturnAsset','LOGIN_ENTRY')
                                        }
                                    }}
                                />
                                <Snackbar open={login_failed} autoHideDuration={3000} onClose={e => set_login_failed(false)} anchorOrigin={{ vertical:'bottom', horizontal: 'center' }} sx={{'&.MuiSnackbar-root': { position:'relative', marginTop:'40px',marginBottom:'-20px', marginRight:'59px' }}}>
                                    <Alert
                                        onClose={e => set_login_failed(false)}
                                        severity="warning"
                                        variant='outlined'
                                        sx={{ width: '100%', fontSize:'18px',transform:'translate(-50%, -50%)' }}
                                    >
                                        Customer ID Not Recognized
                                    </Alert>
                                </Snackbar>
                                <Button variant="outlined" onClick={handleSendMessage} sx={{marginTop:'15px',color:'white', borderWidth:'.5px', borderColor:'#757575'}}>Login</Button>
                            </Stack>
                        </Paper>
                    </Box>
                </Stack>
            }


            {(return_successful === false && return_unsuccessful === false && login === true) &&
                <Stack direction='column'>
                    <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <Paper elevation={8} sx={{width:'450px',paddingLeft:'25px', paddingRight:'25px', paddingBottom:'30px', paddingTop:'0px', textAlign:'center'}} >
                            <img style={{display:'absolute', marginTop:'5px', marginBottom:'-50px'}}  src={require('../Components/UPS_Logo_Alpha.png')} width={"50%"} height={"50%"}></img>
                            <Stack direction='column' sx={{alignItems:'center'}} >
                                <Stack direction='row' sx={{marginLeft:'53px'}}>
                                    <p style={{fontSize:'30px'}}>Return My Asset</p>
                                    <img style={{display:'absolute', marginTop:'38px', marginLeft:'9px'}} src={require('../Components/TrackonomyBeaconLogo.png')} width={"9%"} height={"9%"}></img>
                                </Stack>
                                <TextField
                                    onClick={e => set_message({...{'params':{'data':temp_scanned_value,'context_id':1},'metadata':{'Warp':'Drive'}}})} 
                                    id="qr-input"
                                    label="Scan Product QR Code" 
                                    variant="outlined" 
                                    sx={{width:'300px'}}
                                    // onChange={(e) => handleInput(e.target.value,'qr-input','ReturnAsset','PRODUCT_ENTRY')}
                                    onChange={(e) => handleInput({'string_0':e.target.value},'qr-input','ReturnAsset','PRODUCT_ENTRY')}
                                    value={temp_scanned_value}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter'){
                                            // handleInput('[ENTER]','qr-mac-input','Search','PRODUCT_ENTRY')
                                            handleInput({'string_0':'[ENTER]'},'qr-mac-input','Search','PRODUCT_ENTRY')
                                        }
                                    }}
                                />
                                <Snackbar open={login_success_alert} autoHideDuration={3000} onClose={e => set_login_success_alert(false)} sx={{'&.MuiSnackbar-root': { position:'relative', marginBottom:'-25px', marginTop:'40px', marginRight:'55px' }}}>
                                    <Alert
                                        onClose={e => set_login_success_alert(false)}
                                        severity="success"
                                        variant='outlined'
                                        sx={{fontSize:'16.5px'}}
                                    >
                                        Succesfully Logged In As UPS
                                    </Alert>
                                </Snackbar>
                                <Button variant="outlined" onClick={handleSendMessage} sx={{marginTop:'15px',color:'white', borderWidth:'.5px', borderColor:'#757575'}}>Confirm QR</Button>
                            </Stack>
                        </Paper>
                    </Box>

                    {no_data === true &&
                        <Paper elevation={8} sx={{textAlign:'center', marginTop:'25px'}}>
                            <WarningAmberOutlinedIcon sx={{marginTop:'20px', marginBottom:'-20px', fontSize:'45px', color:'red'}} />
                            <h4>Product Not Found For: {scanned_value}</h4>
                        </Paper>
                    }

                    {(no_data === false && product != '') &&
                        <Paper elevation={12} sx={{textAlign:'center', marginTop:'25px'}}>
                            <Stack direction='column' sx={{alignItems:'center'}}>
                                <Box sx={{backgroundColor:'#1f1f1f', width:'450px', paddingBottom:'15px'}} >
                                    <p style={{fontSize:'20px',marginTop:'25px', marginBottom:'-10px', fontWeight:'bold'}}>{product}</p>
                                    <p style={{fontSize:'18px',marginTop:'10px', marginBottom:'5px'}}>{scanned_value}</p>
                                </Box>
                                {Object.keys(ui_dict['RETURN_FORM']).map((key, index) => {
                                    if (key != 'fieldComment' && key != 'qrcode'){
                                        return(
                                            <TextField
                                                sx={{width:'350px', marginTop:'18px'}}
                                                key={key}
                                                id={key}
                                                label={ui_dict['RETURN_FORM'][key]['label']}
                                                variant="standard"
                                                value={ui_dict['RETURN_FORM'][key]['value']}
                                                // onChange={(e) => handleInput(e.target.value,key,'ReturnAsset','RETURN_FORM')}
                                                onChange={(e) => handleInput({'string_0':e.target.value},key,'ReturnAsset','RETURN_FORM')}
                                                style={{ marginRight: '10px' }}
                                            />
                                        )
                                    }else if (key === 'fieldComment'){
                                        return(
                                            <TextField
                                                sx={{width:'350px', marginTop:'25px', marginBottom:'25px'}}
                                                key={key}
                                                id={key}
                                                label={ui_dict['RETURN_FORM'][key]['label']}
                                                variant="outlined"
                                                multiline={true}
                                                rows={7}
                                                value={ui_dict['RETURN_FORM'][key]['value']}
                                                // onChange={(e) => handleInput(e.target.value,key,'ReturnAsset','RETURN_FORM')}
                                                onChange={(e) => handleInput({'string_0':e.target.value},key,'ReturnAsset','RETURN_FORM')}
                                                style={{ marginRight: '10px' }}
                                            />  
                                        )
                                    }
                                })}
                                <Typography variant="h6" component="h2" gutterBottom>
                                    Upload Shipment PDF
                                </Typography>
                                <Input
                                    sx={{width:'350px',marginBottom:'18px'}}
                                    type="file"
                                    inputProps={{ accept: 'application/pdf' }}
                                    onChange={handleFileChange}
                                />
                                <Snackbar open={show_alert} autoHideDuration={3000} onClose={e => set_show_alert(false)} sx={{'&.MuiSnackbar-root': { position:'relative', marginBottom:'-5px', marginTop:'20px', marginRight:'55px' }}}>
                                    <Alert
                                        onClose={e => set_show_alert(false)}
                                        severity="warning"
                                        variant='outlined'
                                        sx={{fontSize:'16.5px'}}
                                    >
                                        Please Provide All Information
                                    </Alert>
                                </Snackbar>
                                {/* <Button variant="outlined" onClick={() => Object.values(ui_dict['RETURN_FORM']).every(value => value['value'] != '') && pdfFile != null ? set_modal_alert_open(true) : set_show_alert(true)} sx={{marginBottom:'25px',color:'white', borderWidth:'.5px', borderColor:'#757575'}}>Confirm Details</Button> */}
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        const isNotNull = pdfFile !== null;
                                        const allValuesFilled = Object.values(ui_dict['RETURN_FORM']).every(value => value['value'] !== '');

                                        if (isNotNull && allValuesFilled) {
                                            set_modal_alert_open(true);
                                        } else {
                                            set_show_alert(true);
                                        }
                                    }}
                                    sx={{ marginBottom: '25px', color: 'white', borderWidth: '.5px', borderColor: '#757575' }}
                                >
                                    Confirm Details
                                </Button>
                            </Stack>
                        </Paper>
                    }

                    
                    <Modal
                        open={modal_alert_open}
                        onClose={e => set_modal_alert_open(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={{
                                position:'absolute',
                                top:'50%',
                                left:'50%',
                                transform:'translate(-50%, -50%)',
                                width:600,
                                bgcolor:'#333333',
                                border:'2px solid #000',
                                boxShadow: 24,
                                p: 4,
                                textAlign:'center'
                            }}
                        >
                            <Typography sx={{fontWeight:'bold', backgroundColor:'#1f1f1f', width:'600px', marginLeft:'-33.5px', paddingTop:'20px', paddingBottom:'20px', marginTop:'-32px'}} id="modal-modal-title" variant="h6" component="h2">
                            Confirm Return
                            </Typography>
                            <Typography  id="modal-modal-description" sx={{ mt: 2, fontSize:'18px' }}>
                                Are you sure you would like to return this {product}?<br/>{scanned_value}
                            </Typography>
                            <Button variant='contained' onClick={handleSendMessage} sx={{ marginTop:'20px', color:'black', backgroundColor:'white' }}>
                            {/* <Button variant='contained' onClick={handleSendMessageFAKE} sx={{ marginTop:'20px', color:'black', backgroundColor:'white' }}> */}
                                Confirm
                            </Button>
                            <br/>
                            <Button onClick={e => set_modal_alert_open(false)} sx={{ marginTop:'25px', marginBottom:'-10px', color:'red', fontSize:'15px' }}>
                                Cancel
                            </Button>
                        </Box>
                    </Modal>
                    
                </Stack>
            }

            {return_successful === true &&
                
                <Card sx={{position:'absolute', maxWidth: 600, textAlign: 'center', padding: 2, boxShadow: 3, backgroundColor:'#212121',top:'40%',left:'50%', transform:'translate(-50%, -50%)', }}>
                    <CardContent>
                        <Typography variant="h4" gutterBottom>
                        <CheckCircleOutlineIcon sx={{ fontSize: 60, color: green[500], verticalAlign: 'middle', marginRight: 1 }} />
                        {product} <br/> Return Successfully Received
                        </Typography>
                        <Typography sx={{fontSize:'18px'}} color="textSecondary" gutterBottom>
                        Thank you, We have sent a confirmation email to you with some information on how we will process your return.
                        </Typography>
                        <Button onClick={e => (set_return_successful(false),set_product(''))} variant="contained" color="primary" sx={{ marginTop: 3, color:'black', backgroundColor:'#c9c9c9' }}>
                        Back To Home
                        </Button>
                    </CardContent>
                </Card>
                
            }

            {return_unsuccessful === true &&
                
                <Card sx={{position:'absolute', maxWidth: 600, textAlign: 'center', padding: 2, boxShadow: 3, backgroundColor:'#212121',top:'40%',left:'50%', transform:'translate(-50%, -50%)', }}>
                    <CardContent>
                        <Typography variant="h4" gutterBottom>
                        <WarningAmberOutlinedIcon sx={{ fontSize: 60, color: red[500], verticalAlign: 'middle', marginRight: 1 }} />
                        {product} <br/> Error In Sending Request
                        </Typography>
                        <Typography sx={{fontSize:'18px'}} color="textSecondary" gutterBottom>
                        We're sorry, it looks like something went wrong when processing your return request, we are working on resolving this issue.<br/><br/> If you have any questions or concerns please contact:<br/>Bodhi Bhattacharya: bodhi@trackonomysystems.com
                        </Typography>
                        <Button onClick={e => (set_return_unsuccessful(false),set_product(''))} variant="contained" color="primary" sx={{ marginTop: 3, color:'black', backgroundColor:'#c9c9c9' }}>
                        Back To Home
                        </Button>
                    </CardContent>
                </Card>
                
            }
        </Container>
  );
}